enum ProjectOriginEnum {
  FREE_TIME = 'free-time',
  EDUCATION = 'education',
  PROFESSIONAL = 'professional',
  EVENT = 'event',
}

interface TeamMember {
    name: string,
    role: string
}

export interface PortfolioItem {
    projectOrigin: ProjectOriginEnum,
    title: string
    introduction: string
    description: {
        situation: string,
        elaboration: string,
        result: string
    },
    thumbnail: string,
    date: string,
    tags: string[]
}

export const portfolioItems: PortfolioItem[] = [
    {
        projectOrigin: ProjectOriginEnum.PROFESSIONAL,
        title: 'Charging as a Service tool',
        introduction: 'Project of Wisemen. Tool to support the charging proces of electrical assets at airports. Extensive planning tool that includes live features, task generation & alerts.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-caas.webp',
        date: 'Januari 2024 - now',
        tags: [
            'Team project',
            'Tech architect',
            'Node',
            'NestJS',
            'NATS',
            'Postgres'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.PROFESSIONAL,
        title: 'Live chat application',
        introduction: 'Project of Wisemen. Multi-user all-inclusive chat module in domestic helper application. Contains live messaging, automated translations, fuzzy search & read status.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-chat.webp',
        date: 'Januari 2024 - now',
        tags: [
            'Team project',
            'Node',
            'NestJS',
            'NATS',
            'Websockets',
            'Postgres',
            'DeepL'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.FREE_TIME,
        title: 'Controllable WS2812 led strip',
        introduction: 'An ESP32 controlled WS2812 led strip controllable via a self hosted MQTT. Currently in progress to refactor this to make use of the Matter protocol.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-esp32.webp',
        date: 'April 2022 - May 2022',
        tags: [
            'Personal project',
            'ESP32',
            'MQTT',
            'Solf-hosted',
            'Docker'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.PROFESSIONAL,
        title: 'Mobile app - political party',
        introduction: 'Project of Wisemen. Mobile application to stay up-to-date about the political party. Includes videofeed, recommendation engine and multiple integrations.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-social-app.webp',
        date: 'April 2023 - July 2023',
        tags: [
            'Team project',
            'PHP',
            'Laravel',
            'Recommendation engine',
            'Video player'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.EDUCATION,
        title: 'Automated weather station',
        introduction: 'Group project for an external client during my final bachelor\'s year that included an Arduino programmable microcontroller that functioned as a weather station.',
        description: {
            situation: 'During the final year of my bachelor\'s degree, we\'re challenged to analyse and develop a project for an external client from the ground up. Alongside two other teams, our team was assigned to the client "Kris Vandenwyngaert".\n\nKris Vandenwyngaert\'s heart lays with the viticulture:<ul><li>He was project manager of the Belgian viticulture during the period 2014-2019.<li><li>Maintains a website where information is bundled.</li><li>Invented a weather station dedicated to the viticulture.</li></ul>\n\nOur task included: <ul><li>Implement OTA updates.</li><li>Create a webapp to manage weather stations.</li><li>Visualise data gathered by the weather station.</li><li>Make it posibile to share your data with others.</li><li>Modify the existing Arduino code of the weather station.</li></ul>.',
            elaboration: 'The project was broken up into 3 phases: ',
            result: ''
        },
        thumbnail: 'tile-weather-staton.webp',
        date: 'September 2021 - Februari 2022',
        tags: [
            'IoT',
            'Project manager',
            'Team project',
            'Arduino'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.FREE_TIME,
        title: 'Website GymX',
        introduction: 'An informative website for the gymastics club I\'m trainer of. Fully designed and developed by myself.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-gymx-website.webp',
        date: 'April 2022 - May 2022',
        tags: [
            'Personal project',
            'Website',
            'TypeScript',
            'React',
            'Self-maintained',
            'DigitalOcean'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.FREE_TIME,
        title: 'Camp game "File X"',
        introduction: 'A interactive game with story line for the camp of the gymastics club I\'m trainer of to let the youngsters enjoy one of their activities in an alternative way.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-file-x.webp',
        date: 'April 2022 - May 2022',
        tags: [
            'Personal project',
            'Webapp',
            'PHP',
            'Laravel'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.EDUCATION,
        title: 'Fruits image recognition',
        introduction: 'A self-trained Convolutional Neural Network that makes use of transfer learning with an accuracy of 96,6% that can differentiate between 5 different fruits.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-image-recognition.webp',
        date: 'November 2021 - December 2021',
        tags: [
            'Team project',
            'AI',
            'Python',
            'Flask',
            'Selenium'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.FREE_TIME,
        title: 'Tennis court lightning',
        introduction: 'Concept to switch 380V lights of my local tennis club\'s courts through a web interface. Includes debounce functionality to avoid damage to the lights.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-tennis-lightning.webp',
        date: 'March 2021 - May 2021',
        tags: [
            'IoT',
            'Raspberry Pi',
            'Python',
            'Flask'
        ]
    },
    {
        projectOrigin: ProjectOriginEnum.PROFESSIONAL,
        title: 'Mobile app - training app',
        introduction: 'Project of Wisemen. Advanced training app focused on soccer that offers personalized training schedules. Contains heavy video management, social features and statistics.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-training-app.webp',
        date: 'Januari 2024 - now',
        tags: [
            'Team project',
            'PHP',
            'Laravel',
            'S3',
            'MySQL',
            'Social'
        ]
    },
    // {
    //     projectOrigin: ProjectOriginEnum.FREE_TIME,
    //     title: 'Retouching website',
    //     introduction: 'An informative website for a local retouching business. Fully designed and developed by myself.',
    //     description: {
    //         situation: '',
    //         elaboration: '',
    //         result: ''
    //     },
    //     thumbnail: 'tile-retouching-website.webp',
    //     date: '',
    //     tags: [
    //         'Personal project',
    //         'Website',
    //         'TypeScript',
    //         'React',
    //         'Self-maintained'
    //     ]
    // },
    {
        projectOrigin: ProjectOriginEnum.EVENT,
        title: 'First place "Hack the future"',
        introduction: 'A hackathon organized by "Cronos group" with the challenge of developing an application in teams of 2 with Microsoft 365 tools like Power Apps, Power Automate & Sharepoint.',
        description: {
            situation: '',
            elaboration: '',
            result: ''
        },
        thumbnail: 'tile-hack-the-future.webp',
        date: 'December 2nd 2021',
        tags: [
            'Hackathon',
            'Team project',
            'Microsoft 365',
            'Full-stack'
        ],
    }
]