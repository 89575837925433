import Icon, { IconName } from './shared/Icon';

export default function Resume() {
	return (
		<div id='resume' className='border-8 border-dark-800 rounded-xl p-12'>
			<h2 className='font-ubuntu text-4xl font-bold'>Resume</h2>
			<div className='grid grid-cols-1 lg:grid-cols-2 mt-8'>
				<div>
					<h3 className='font-ubuntu text-2xl font-semibold'>Experience</h3>				
					<ol className="relative border-s border-gray-700 mx-4 mt-4">                  
						<li className="mb-10 ms-4">
							<div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700" data-aos="fade-up"></div>
							<time className="mb-1 text-sm font-normal leading-none text-gray-400" data-aos="fade-up">September 2024</time>
							<h3 className="text-lg font-semibold text-white" data-aos="fade-up"><a href="https://wisemen.digital/">Wisemen</a> - Tech architect</h3>
							<p className="mb-4 text-base font-normal text-gray-400" data-aos="fade-up">Aside from developing, I'm responsible for the technical execution of several client projects.</p>
						</li>
						<li className="mb-10 ms-4">
							<div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700" data-aos="fade-up"></div>
							<time className="mb-1 text-sm font-normal leading-none text-gray-400" data-aos="fade-up">November 2022</time>
							<h3 className="text-lg font-semibold text-white" data-aos="fade-up"><a href="https://wisemen.digital/">Wisemen</a> - Backend software developer</h3>
							<p className="text-base font-normal text-gray-400" data-aos="fade-up">Developing challenging backend applications from ground-up in PHP (Laravel) and Node.js (NestJs).</p>
						</li>
						<li className="ms-4">
							<div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700" data-aos="fade-up"></div>
							<time className="mb-1 text-sm font-normal leading-none text-gray-400" data-aos="fade-up">July 2022</time>
							<h3 className="text-lg font-semibold text-white" data-aos="fade-up"><a href="https://www.viewandintegrate.be/">View And Integrate</a> - Software Development Student</h3>
							<p className="text-base font-normal text-gray-400" data-aos="fade-up">Smart camera solution company with custom VMS system. Developing backend application in Python (FastAPI) & mobile application in Flutter.</p>
						</li>
					</ol>
				</div>
				<div className='mt-16 lg:mt-0'>
					<h3 className='font-ubuntu text-2xl font-semibold'>Education</h3>
					<ol className="relative border-s border-gray-700 mx-4 mt-4">                  
						<li className="mb-10 ms-4">
							<div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700" data-aos="fade-up"></div>
							<time className="mb-1 text-sm font-normal leading-none text-gray-400" data-aos="fade-up">2019 - 2022</time>
							<h3 className="text-lg font-semibold text-white" data-aos="fade-up">Bachelor's degree - Applied computer science</h3>
							<p className="mb-4 text-base font-normal text-gray-400" data-aos="fade-up">Grade: Summa cum laude</p>
						</li>
						<li className="mb-10 ms-4">
							<div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700" data-aos="fade-up"></div>
							<time className="mb-1 text-sm font-normal leading-none text-gray-400" data-aos="fade-up">2017 - 2019</time>
							<h3 className="text-lg font-semibold text-white" data-aos="fade-up">High school - IT management</h3>
						</li>
						<li className="mb-10 ms-4">
							<div className="absolute w-3 h-3 rounded-full mt-1.5 -start-1.5 border border-gray-900 bg-gray-700" data-aos="fade-up"></div>
							<time className="mb-1 text-sm font-normal leading-none text-gray-400" data-aos="fade-up">2014 - 2017</time>
							<h3 className="text-lg font-semibold text-white" data-aos="fade-up">High school - Electrical engineering</h3>
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}
