import { MouseEventHandler, SyntheticEvent, useEffect, useState } from 'react';
import Icon, { IconName } from './shared/Icon'
import Line, { OrientationEnum } from './shared/Circuit/Line';

export default function Hero() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const [ mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const [social1Active, setSocial1Active] = useState(false);
	const [social2Active, setSocial2Active] = useState(false);
	const [social3Active, setSocial3Active] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
        const updateMousePosition = (ev:any) => {
            setMousePosition({ x: ev.clientX, y: ev.clientY });
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
	}, []);

	const handleMouseEnterSocial = (social: string) => {
		switch (social) {
			case 'linkedin':
				setSocial1Active(true)
				break;
			case 'github':
				setSocial2Active(true)
				break;
			case 'x':
				setSocial3Active(true)
				break;
			default:
				break;
		}
	}

	const handleMouseLeaveSocial = (social: string) => {
		switch (social) {
			case 'linkedin':
				setSocial1Active(false)
				break;
			case 'github':
				setSocial2Active(false)
				break;
			case 'x':
				setSocial3Active(false)
				break;
			default:
				break;
		}
	}

	const image = {
		size: {
			height: 200,
			width: 200,
		},
		position: {
			top: height/3-(100),
			left: width/3-(100)
		}
	}
	const name = {
		size: {
			height: 150,
			width: 400,
		},
		position: {
			top: height/2-(75),
			left: (width/3)*2-(200)
		}
	}
	const social1 = {
		size: {
			height: 80,
			width: 80,
		},
		position: {
			top: name.position.top+name.size.height+30,
			left: name.position.left+40
		}
	}
	const social2 = {
		size: {
			height: 80,
			width: 80,
		},
		position: {
			top: name.position.top+name.size.height+30,
			left: name.position.left+40+80+40
		}
	}
	const social3 = {
		size: {
			height: 80,
			width: 80,
		},
		position: {
			top: name.position.top+name.size.height+30,
			left: name.position.left+40+80+40+80+40
		}
	}

	const lengthBetweenImageAndName = name.position.left-(image.position.left+image.size.width)
	const hightBetweenImageAndName = (name.position.top+(name.size.height/2))-(image.position.top+(image.size.height/2))

	const heightBetweenNameAndSocials = social1.position.top-(name.position.top+name.size.height)

	const heightBetweenImageAndBottom = height-(image.position.top+image.size.height)

	return (
		<div 
			className='h-screen relative hidden lg:block'
			style={{
				// Mouse position radial gradient
				backgroundImage: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(255,255,255,0.05), rgba(0,0,0,0) 20% )`
			}}
		>

			{/* Image */}
			<div 
				className='absolute bg-gradient-to-tr from-dark-900 to-dark-800 rounded-xl flex' 
				style={{
					width: `${image.size.width}px`,
					height: `${image.size.height}px`,
					top: `${image.position.top}px`, 
					left: `${image.position.left}px`, 
					boxShadow: '5px 5px 0px 5px #272727'
				}}
			>
				<img
					data-aos="zoom-in"
					className='rounded-lg w-10/12 h-10/12 mx-auto my-auto'
					src={require('./assets/pieter.webp')}
					alt="Pieter Pauwels"
				/>			
			</div>

			{/* Name */}
			<div
				className='absolute bg-gradient-to-tl from-dark-900 to-dark-800 rounded-xl'
				style={{
					width: `${name.size.width}px`,
					height: `${name.size.height}px`,
					top: `${name.position.top}px`, 
					left: `${name.position.left}px`, 
					boxShadow: '5px 5px 0px 5px #272727'
				}}
			>
				<div className='flex flex-col h-full justify-center font-ubuntu' data-aos="zoom-in">
					<h1 className='uppercase text-center text-4xl font-bold'>Pieter Pauwels</h1>
					<h2 className='text-center text-xl mt-2'>Welcome to my portfolio!</h2>
				</div>
			</div>

			{/* Socials */}
			<div
				onMouseEnter={e => handleMouseEnterSocial('linkedin')}
				onMouseLeave={e => handleMouseLeaveSocial('linkedin')}
				className='absolute bg-dark-900 rounded-xl hover:bg-gradient-to-l hover:from-sky-500 hover:to-green-500 hover:animate-pulse'
				style={{
					width: `${social1.size.width}px`,
					height: `${social1.size.height}px`,
					top: `${social1.position.top}px`, 
					left: `${social1.position.left}px`, 
					boxShadow: '2px 2px 0px 2px #272727'
				}}
			>
				<a href="https://www.linkedin.com/in/pieter-pauwels-b73a471b4/" target='_blank'>
					<div className='h-full flex justify-center items-center scale-200 hover:bg-red'>
						<Icon name={IconName.LINKEDIN} color='white'></Icon>
					</div>
				</a>
			</div>
			<div
				onMouseEnter={e => handleMouseEnterSocial('github')}
				onMouseLeave={e => handleMouseLeaveSocial('github')}
				className='absolute bg-dark-900 rounded-xl hover:bg-gradient-to-l hover:from-sky-500 hover:to-green-500 hover:animate-pulse'
				style={{
					width: `${social2.size.width}px`,
					height: `${social2.size.height}px`,
					top: `${social2.position.top}px`, 
					left: `${social2.position.left}px`, 
					boxShadow: '2px 2px 0px 2px #272727'
				}}
			>
				<a href="https://github.com/PauwelsPieter" target='_blank'>
					<div className='h-full flex justify-center items-center scale-200'>
					<Icon name={IconName.GITHUB} color='white'></Icon>
				</div>
				</a>
			</div>
			<div
				onMouseEnter={e => handleMouseEnterSocial('x')}
				onMouseLeave={e => handleMouseLeaveSocial('x')}
				className='absolute bg-dark-900 rounded-xl hover:bg-gradient-to-l hover:from-sky-500 hover:to-green-500 hover:animate-pulse'
				style={{
					width: `${social3.size.width}px`,
					height: `${social3.size.height}px`,
					top: `${social3.position.top}px`, 
					left: `${social3.position.left}px`, 
					boxShadow: '2px 2px 0px 2px #272727'
				}}
			>
				<a href="https://twitter.com/pauwels_pieter" target='_blank'>
					<div className='h-full flex justify-center items-center scale-150'>
						<Icon name={IconName.X} color='white'></Icon>
					</div>
				</a>
			</div>

			{/* Lines from image to name */}
			<Line orientation={OrientationEnum.HORIZONTAL} active={true} lengthPx={(lengthBetweenImageAndName/3)*2} topOffset={`${image.position.top+(image.size.height/2)}px`} leftOffset={`${image.position.left+image.size.width+4}px`} rounding={{side2: false}}></Line>
			<Line orientation={OrientationEnum.VERTICAL} active={true} lengthPx={hightBetweenImageAndName} topOffset={`${image.position.top+(image.size.height/2)}px`} leftOffset={`${image.position.left+image.size.width+4+((lengthBetweenImageAndName/3)*2)}px`} reverseGradient={true} rounding={{side1: false, side2: false}}></Line>
			<Line orientation={OrientationEnum.HORIZONTAL} active={true} lengthPx={(lengthBetweenImageAndName/3)*1-4} topOffset={`${image.position.top+(image.size.height/2)+hightBetweenImageAndName}px`} leftOffset={`${image.position.left+image.size.width+4+((lengthBetweenImageAndName/3)*2)}px`} rounding={{side1: false, side2: false}}></Line>

			{/* Lines from name to socials */}	
			<Line orientation={OrientationEnum.VERTICAL} active={social1Active} lengthPx={heightBetweenNameAndSocials-4} topOffset={`${social1.position.top-heightBetweenNameAndSocials+4}px`} leftOffset={`${social1.position.left+(social1.size.width/2)}px`} rounding={{side2: false}}></Line>
			<Line orientation={OrientationEnum.VERTICAL} active={social2Active} lengthPx={heightBetweenNameAndSocials-4} topOffset={`${social2.position.top-heightBetweenNameAndSocials+4}px`} leftOffset={`${social2.position.left+(social2.size.width/2)}px`} rounding={{side2: false}}></Line>
			<Line orientation={OrientationEnum.VERTICAL} active={social3Active} lengthPx={heightBetweenNameAndSocials-4} topOffset={`${social3.position.top-heightBetweenNameAndSocials+4}px`} leftOffset={`${social3.position.left+(social3.size.width/2)}px`} rounding={{side2: false}}></Line>

			{/* Lines to next section */}
			<Line orientation={OrientationEnum.VERTICAL} active={true} lengthPx={heightBetweenImageAndBottom-4} topOffset={`${image.position.top+image.size.height+4}px`} leftOffset={`${image.position.left+(image.size.width/2)-20}px`} rounding={{side2: false}}></Line>
			<Line orientation={OrientationEnum.VERTICAL} active={true} lengthPx={heightBetweenImageAndBottom-4} topOffset={`${image.position.top+image.size.height+4}px`} leftOffset={`${image.position.left+(image.size.width/2)+20}px`} rounding={{side2: false}}></Line>
			<Line orientation={OrientationEnum.VERTICAL} active={true} lengthPx={heightBetweenImageAndBottom-4} topOffset={`${image.position.top+image.size.height+4}px`} leftOffset={`${image.position.left+(image.size.width/2)}px`} rounding={{side2: false}}></Line>
		</div>
	)
}
