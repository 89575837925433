import Icon, { IconName } from './shared/Icon';

export default function Languages() {
	return (
		<div id='languages' className='border-8 border-dark-800 rounded-xl p-12'>
			<h2 className='font-ubuntu text-4xl font-bold'>Languages</h2>
			<div className='flex items-center mt-8' data-aos="fade-up">
				<div className='scale-150'>
					<Icon name={IconName.CHEVRON_RIGHT} color='white'></Icon>
				</div>
				<p className='text-lg ml-1'>
					<span className='font-bold'>Dutch/Flemish:</span>
					<span className='ml-2'>Native</span>
				</p>
			</div>
			<div className='flex items-center mt-4' data-aos="fade-up">
				<div className='scale-150'>
					<Icon name={IconName.CHEVRON_RIGHT} color='white'></Icon>
				</div>
				<p className='text-lg ml-1'>
					<span className='font-bold'>English:</span>
					<span className='ml-2'>Full professional proficiency</span>
				</p>
			</div>
			<div className='flex items-center mt-4' data-aos="fade-up">
				<div className='scale-150'>
					<Icon name={IconName.CHEVRON_RIGHT} color='white'></Icon>
				</div>
				<p className='text-lg ml-1'>
					<span className='font-bold'>French:</span>
					<span className='ml-2'>Elementary proficiency</span>
				</p>
			</div>
		</div>
	)
}
