import { useRouteError } from 'react-router-dom'

export default function ErrorPage() {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const error = useRouteError() as any
	console.error(error)

	return (
		<div
			id="error-page"
			className="w-screen h-screen text-center flex flex-col justify-center bg-opacity-50 bg-black"
		>
			<h1 className="text-5xl text-primary font-bold mb-8">Oops!</h1>
			<p className="text-2xl">Een onverwachte fout heeft zich voorgedaan.</p>
			<p className="text-xl text-gray-400 mt-12">
				<i>
					{error.status} - {error.statusText || error.message}
				</i>
			</p>
			<a className="text-primary font-bold underline mt-20" href="/">
				Terug naar home
			</a>
		</div>
	)
}
