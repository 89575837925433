import dayjs from 'dayjs';
import Icon, { IconName } from './shared/Icon';

export default function About() {
	const age = dayjs().diff(dayjs('2001-08-17'), 'years')

	return (
		<div id='about' className='border-8 border-dark-800 rounded-xl p-12'>
			<h2 className='font-ubuntu text-4xl font-bold'>About</h2>
			<p className='mt-4' data-aos="fade-up">My name is Pieter Pauwels, a {age}-year-old with a passion for blanding the worlds of Software and IoT. Driven by my curiosity, I'm determined to chase my passion whilst making some bold moves!</p>
			<p className='mt-4' data-aos="fade-up">I draw my energy from helping others, staying active through sports and realising impactful results. I balance collaborative and personal growth, always looking for new ways to challenge myself and evolve.</p>
		
			<div className='flex items-center mt-12' data-aos="fade-up">
				<div className='scale-150'>
					<Icon name={IconName.CHEVRON_RIGHT} color='white'></Icon>
				</div>
				<p className='text-lg ml-1'>
					<span className='font-bold'>Age:</span>
					<span className='ml-2'>{age}</span>
				</p>
			</div>
			<div className='flex items-center mt-2' data-aos="fade-up">
				<div className='scale-150'>
					<Icon name={IconName.CHEVRON_RIGHT} color='white'></Icon>
				</div>
				<p className='text-lg ml-1'>
					<span className='font-bold'>E-mail:</span>
					<span className='ml-2'><a href="mailto:pieter.pauwels.pp@gmail.com">pieter.pauwels.pp@gmail.com</a></span>
				</p>
			</div>
		</div>
	)
}
