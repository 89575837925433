import Icon, { IconName } from '../shared/Icon';
import { portfolioItems } from './portfolio-items';
import PortfolioItem from './PortfolioItem';

export default function Portfolio() {
	return (
		<div id='portfolio' className='border-8 border-dark-800 rounded-xl px-6 sm:px-12 py-12'>
			<h2 className='font-ubuntu text-4xl font-bold px-6 sm:px-0'>Portfolio</h2>
			<div className='mt-8'>
				{
					portfolioItems.map(function(object, i) {
						return <PortfolioItem key={i} item={object} aos={i%2 === 0 ? 'fade-right' : 'fade-left'}></PortfolioItem>
					})
				}
			</div>
		</div>
	)
}
