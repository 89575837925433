import { useState } from 'react';
import Icon, { IconName } from '../shared/Icon';
import { type PortfolioItem } from './portfolio-items';

type PortfolioItemProps = {
	item: PortfolioItem
	aos: 'fade-right' | 'fade-left'
}

export default function PortfolioItem(props: PortfolioItemProps) {
	const [itemActive, setItemActive] = useState(false);

	const handleMouseEnterItem = () => {
		setItemActive(true)
	}

	const handleMouseLeaveItem = () => {
		setItemActive(false)
	}

	return (
		<div className='bg-white/5 backdrop-blur-sm border border-white/15 rounded-xl my-4 p-8' data-aos={props.aos}>
			<div className='flex flex-col sm:flex-row'>
				<div className='w-2/3 md:w-2/5 h-full'>
					<h3 className='font-ubuntu text-2xl font-bold'>{props.item.title}</h3>
					<div className='flex flex-wrap mt-2 h-full'>
						<div className='bg-green-500/50 w-min px-2 rounded-full text-nowrap mr-2 my-1'>
							<p className='text-sm font-medium capitalize'>{props.item.projectOrigin}</p>
						</div>
						{
							props.item.tags.map(function (object, i){
								return <div key={i} className='bg-sky-500/50 w-min px-2 rounded-full text-nowrap mr-2 my-1'>
									<p className='text-sm font-medium'>{object}</p>
								</div>
							})
						}
					</div>
					<p className='mt-4 text-gray-300 text-lg'>{props.item.introduction}</p>
					<p className='mt-10'>Want to hear more? Get in <a className='text-sky-500/80 font-semibold' href="mailto:pieter.pauwels.pp@gmail.com">contact</a>!</p>
				</div>
				<img
					className='mt-8 w-2/3 mx-auto sm:mt-0 sm:w-1/3 h-full top-0 sm:ml-auto sm:mr-0 rounded-lg'
					src={require(`../assets/portfolio/${props.item.thumbnail}`)}
					alt="Thumbnail tile"
				/>
			</div>
		</div>
	)
}
