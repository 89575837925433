export enum OrientationEnum {
	HORIZONTAL = 'horizontal',
	VERTICAL = 'vertical'
}

type LineProps = {
	orientation: OrientationEnum
	lengthPx: number
    topOffset: string
    leftOffset: string
    rounding?: {
        side1?: boolean
        side2?: boolean
    },
    reverseGradient?: boolean
    thickness?: number
    active?: boolean
}

export default function Line(props: LineProps) {
    const thickness = props.thickness ?? 4
    const width = props.orientation === OrientationEnum.HORIZONTAL ? props.lengthPx : thickness
    const height = props.orientation === OrientationEnum.VERTICAL ? props.lengthPx : thickness

    const activeClasses = props.active ? 'animate-pulse from-sky-500 to-green-500' : ''
    let bgClass = 'bg-dark-700'
    if (props.active) {
        bgClass = props.orientation === OrientationEnum.HORIZONTAL ?
        props.reverseGradient ?  'bg-gradient-to-l' :  'bg-gradient-to-r' 
        : props.reverseGradient ? 'bg-gradient-to-t' : 'bg-gradient-to-b'
    }

    let roundingClass = 'rounded-lg'
    if (props.rounding !== undefined) {
        if ((props.rounding.side1 ?? true) && (props.rounding.side2 ?? true)) {
            roundingClass = props.orientation === OrientationEnum.HORIZONTAL ? 'rounded-l-lg rounded-r-lg' : 'rounded-t-lg rounded-b-lg'
        }
        else if (props.rounding.side1 ?? true) {
            roundingClass = props.orientation === OrientationEnum.HORIZONTAL ? 'rounded-l-lg' : 'rounded-t-lg' 
        }
        else if (props.rounding.side2 ?? true) {
            roundingClass = props.orientation === OrientationEnum.HORIZONTAL ? 'rounded-r-lg' : 'rounded-b-lg' 
        }
        else {
            roundingClass = ''
        }
    }

	return (
        <>
            <div
                className={`absolute` + ` ${activeClasses}` + ` ${bgClass}` + ` ${roundingClass}`}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    top: `${props.topOffset}`, 
                    left: `${props.leftOffset}` 
                }}
            >
            </div>
            <div
                className={`absolute` + ` ${activeClasses}` + (props.active ? ' blur-sm' : '') + ` ${bgClass}` + ` ${roundingClass}`}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    top: `${props.topOffset}`, 
                    left: `${props.leftOffset}` 
                }}
            >
            </div>
        </>
	)
}
