import Line, { OrientationEnum } from "./Circuit/Line";

type SectionLinesProps = {
	side: 'left' | 'right'
}

export default function SectionLines(props: SectionLinesProps) {
	const leftOffset = props.side === 'left' ? 25 : 75

	return (
		<div className="relative h-[200px]">
            <Line orientation={OrientationEnum.VERTICAL} lengthPx={200} topOffset={`0px`} leftOffset={`calc(${leftOffset}% - 20px)`} active={true} rounding={{side1: false, side2: false}}></Line>
            <Line orientation={OrientationEnum.VERTICAL} lengthPx={200} topOffset={`0px`} leftOffset={`${leftOffset}%`} active={true} rounding={{side1: false, side2: false}}></Line>
            <Line orientation={OrientationEnum.VERTICAL} lengthPx={200} topOffset={`0px`} leftOffset={`calc(${leftOffset}% + 20px)`} active={true} rounding={{side1: false, side2: false}}></Line>
        </div>
	)
}
