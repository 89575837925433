import { ReactSVG } from 'react-svg'

export enum IconName {
	BARS = 'bars',
	CHEVRON_DOWN = 'chevron-down',
	CHEVRON_RIGHT = 'chevron-right',
	CLOSE = 'close',
	FACEBOOK = 'facebook',
	GITHUB = 'github',
	INSTAGRAM = 'instagram',
	LINKEDIN = 'linkedin',
	MAIL = 'mail',
	X = 'x',
}

type IconProps = {
	name: IconName
	color: string
}

export default function Icon(props: IconProps) {
	return (
		<ReactSVG
			data-aos="zoom-in"
			src={require(`../assets/icons/${props.name}.svg`)}
			beforeInjection={(svg) => {
				svg.setAttribute('style', `color: ${props.color}`)
			}}
		/>
	)
}
